import _createForOfIteratorHelper from "E:/DUMENGZHAO/Work/blog-web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import LunarCalenda from '../tools/LunarCalendar';
export default {
  props: ['initCalendar'],
  setup: function setup() {
    return {
      weeks: ['一', '二', '三', '四', '五', '六', '日'],
      calendarDateCells: 42,
      calendarMonthCells: 16,
      calendarYearCells: 16
    };
  },
  data: function data() {
    console.log('init calendar ==> ' + this.initCalendar);
    var calendarDate, nowMonth, nowYear;

    if (this.initCalendar) {
      calendarDate = new Date();
      nowMonth = calendarDate.getMonth() + 1;
      nowYear = calendarDate.getFullYear();
    }

    return {
      calendarDate: calendarDate,
      nowMonth: nowMonth,
      nowYear: nowYear,
      calendarSwitching: false,
      nowCalendarView: 0 //当前视图模式，默认：0天视图，1：月视图，2：年视图

    };
  },
  methods: {
    is_leap: function is_leap(year) {
      //闰年的条件是符合下面二者之一：
      //(1)年份能被4整除，但不能被100整除；
      //(2)年份能被400整除。
      if (year % 4 == 0 && year % 100 != 0 || year % 400 == 0) {
        return 1;
      }

      return 0;
    },
    addClass: function addClass(dom, name) {
      dom.className += ' ' + name;
    },
    setCalendarCell: function setCalendarCell(cell, y, m, d) {
      var solarText = cell.getElementsByClassName('solar-calendar')[0];
      var lunarText = cell.getElementsByClassName('lunar-calendar')[0];
      solarText.innerHTML = d;
      var lunarCalendarDay = LunarCalenda.format(LunarCalenda.calculationLunarDay(y, m, d));
      lunarText.innerHTML = lunarCalendarDay;
      var that = this; //绑定当前节点点击事件，点击添加样式并移除兄弟节点样式

      cell.addEventListener('click', function () {
        that.addClass(cell, 'calendar-cell-active');
        var p = cell.parentNode.children;

        for (var i = 0, pl = p.length; i < pl; i++) {
          if (p[i] !== cell) {
            p[i].classList.remove('calendar-cell-active');
          }
        }
      });
    },
    renderCalendar: function renderCalendar(dom, callback) {
      var date = this.calendarDate;
      var year = date.getFullYear(); //获取年份

      var month = date.getMonth(); //获取月份，返回值是0(一月)到11(十二月)之间的一个整数。

      var now_month = month + 1;
      var day = date.getDate(); //获取日期，返回值是1~31之间的一个整数

      var first_date = new Date(year, month, 1); //获取当前月第一天Date
      //返回date对象星期中的一天，此值为0(周日)-6(周六)之间的一个整数

      var week = first_date.getDay(); //获取当前月第一天是星期几，默认返回0至6，0代表星期日，1至6代表星期一至星期六，

      week = week === 0 ? 7 : week;
      var days_of_month = new Array(31, 28 + this.is_leap(year), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31); //各月份的总天数，0表示一月，1表示二月，2表示三月，依此类推。

      var days = days_of_month[month]; //返回当月天数
      //let rows = Math.ceil((days_of_month[month] + week) / 7); //表格所需要行数
      //let HH = date.getHours().toString().padStart(2,'0') , MM = date.getMinutes().toString().padStart(2,'0') , SS = date.getSeconds().toString().padStart(2,'0');

      document.getElementById('myCalendarYearSelect').innerText = year + '年';
      document.getElementById('myCalendarMonthSelect').innerText = now_month + '月'; //let calendarDateDomArray = document.getElementsByClassName('calendar-date-render');
      //let calendarDateCells = calendarDateDomArray[calendarDateDomArray.length-1].getElementsByClassName('calendar-cell');

      var calendarDateCells = dom.getElementsByClassName('calendar-cell');
      var index = 1,
          startCellIndex = week - 1,
          endCellIndex = days + week - 2;
      var prevMonthStartDay,
          prevMonthYear = year,
          prevMonthMonth = now_month - 1;

      if (month === 0) {
        prevMonthYear = year - 1;
        prevMonthMonth = 12;
        prevMonthStartDay = 31 - week + 2;
      } else {
        prevMonthStartDay = days_of_month[month - 1] - week + 2;
      } //console.log(week,month,prevMonthYear,prevMonthMonth,prevMonthStartDay)


      var nextMonthStartDay = 1,
          nextMonthYear = year,
          nextMonthMonth = now_month + 1;

      if (month === 11) {
        nextMonthYear = year + 1;
        nextMonthMonth = 1;
      } //console.log(nextMonthYear,nextMonthMonth,nextMonthStartDay)


      for (var i = 0; i < calendarDateCells.length; i++) {
        var cell = calendarDateCells[i];

        if (i < startCellIndex) {
          this.setCalendarCell(cell, prevMonthYear, prevMonthMonth, prevMonthStartDay);
          prevMonthStartDay++;
          this.addClass(cell, 'not-now-text');
        } else if (i > endCellIndex) {
          this.setCalendarCell(cell, nextMonthYear, nextMonthMonth, nextMonthStartDay);
          nextMonthStartDay++;
          this.addClass(cell, 'not-now-text');
        } else {
          this.setCalendarCell(cell, year, now_month, index);

          if (index === day) {
            this.addClass(cell, 'now-day');
          }

          index++;
        }
      }

      callback ? callback(true) : null;
    },
    switchCalendar: function switchCalendar(date, type, callback) {
      var _this = this;

      if (this.calendarSwitching) return;
      var that = this;
      this.calendarSwitching = true;
      this.calendarDate = date;
      var calendarDateFirstDom = document.getElementsByClassName('calendar-date-render')[0];
      var calendarDateDom = calendarDateFirstDom.cloneNode(true);
      var calendarDateDomHeight = calendarDateFirstDom.getBoundingClientRect().height;
      var calendar_date_list = document.getElementById('calendar_date_list');
      var calendarDateCells = calendarDateDom.getElementsByClassName('calendar-cell');

      var _iterator = _createForOfIteratorHelper(calendarDateCells),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var d = _step.value;
          d.classList.remove('now-day');
          d.classList.remove('not-now-text');
          d.classList.remove('calendar-cell-active');
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      if (!isNaN(type)) {
        that.nowCalendarView = 0;
        this.renderCalendar(calendarDateDom, function () {
          calendar_date_list.appendChild(calendarDateDom);
          calendar_date_list.removeChild(calendarDateFirstDom);

          _this.addClass(document.getElementsByClassName('calendar-date-render')[0], 'calendar-date-enlarge');

          document.getElementsByClassName('render-days-layer')[0].removeAttribute('style');
          document.getElementsByClassName('render-month-layer')[0].removeAttribute('style');
          document.getElementsByClassName('render-year-layer')[0].removeAttribute('style'); //删除动画

          setTimeout(function () {
            $('.calendar-date-render').removeClass('calendar-date-enlarge');
            that.calendarSwitching = false;
            callback ? callback(true) : null;
          }, 350);
        });
        return;
      }

      this.renderCalendar(calendarDateDom, function () {
        // $('#calendar_date_list').animate({top: '-' + calendarDateDomHeight + 'px'},500,function(){
        // })
        if (type === 'next') {
          calendar_date_list.appendChild(calendarDateDom);
          $('#calendar_date_list').css({
            height: calendarDateDomHeight * 2,
            bottom: '-' + calendarDateDomHeight + 'px'
          }); //calendar_date_list.style.height = calendarDateDomHeight * 2
          //calendar_date_list.style.bottom = '-'+calendarDateDomHeight+'px'

          $('#calendar_date_list').animate({
            bottom: '0px'
          }, 300, function () {
            calendar_date_list.removeChild(calendarDateFirstDom);
            calendar_date_list.removeAttribute('style');
            that.calendarSwitching = false;
            callback ? callback(true) : null;
          });
        } else {
          $('#calendar_date_list').css({
            height: calendarDateDomHeight * 2,
            top: '-' + calendarDateDomHeight + 'px'
          });
          calendar_date_list.insertBefore(calendarDateDom, calendarDateFirstDom);
          $('#calendar_date_list').animate({
            top: '0px'
          }, 300, function () {
            calendar_date_list.removeChild(calendarDateFirstDom);
            calendar_date_list.removeAttribute('style');
            that.calendarSwitching = false;
            callback ? callback(true) : null;
          });
        } //calendar_date_list.style.height = calendarDateDomHeight * 2
        //calendar_date_list.style.top = '0px'

      }); //console.log(type,calendarDateDomHeight)
      // let myCalendarContentHeight = myCalendarContent.offsetWidth;
      // myCalendarContent.style.height = myCalendarContentHeight + 'px'
      // let firstCalendarDate = $('.my-calendar-content > .calendar-date-render:first');
      // firstCalendarDate.addClass('calendar-date-narrow');
      // setTimeout(()=>{
      //     firstCalendarDate.remove()
      // },300)
    },
    renderCalendarMonthView: function renderCalendarMonthView() {
      var _this2 = this;

      var that = this;
      var calendarMonthCells = document.getElementsByClassName('calendar-month-render')[0].getElementsByClassName('calendar-cell');

      var _loop = function _loop(i) {
        var cell = calendarMonthCells[i];
        var m = cell.getElementsByClassName('text')[0].innerText;

        if (parseInt(m) === _this2.nowMonth) {
          _this2.addClass(cell, 'now-day');
        }

        cell.addEventListener('click', function () {
          document.getElementsByClassName('calendar-week')[0].removeAttribute('style');
          var m = cell.getElementsByClassName('text')[0].innerText;
          var date = that.calendarDate;
          date.setMonth(parseInt(m) - 1);
          that.switchCalendar(date, parseInt(m), function () {});
        });
      };

      for (var i = 0; i < calendarMonthCells.length; i++) {
        _loop(i);
      }
    },
    renderCalendarYearView: function renderCalendarYearView(dom, callback) {
      var _this3 = this;

      var that = this;
      var calendarYearCells = dom.getElementsByClassName('calendar-cell');
      var n = this.calendarDate.getFullYear() - 7;

      var _loop2 = function _loop2(i) {
        var cell = calendarYearCells[i];
        cell.getElementsByClassName('text')[0].innerText = n;
        n++;
        var y = cell.getElementsByClassName('text')[0].innerText;

        if (parseInt(y) === _this3.nowYear) {
          _this3.addClass(cell, 'now-day');
        }

        cell.addEventListener('click', function () {
          document.getElementsByClassName('calendar-week')[0].removeAttribute('style');
          var y = cell.getElementsByClassName('text')[0].innerText;
          var date = that.calendarDate;
          date.setFullYear(y);
          that.switchCalendar(date, y, function () {});
        });
      };

      for (var i = 0; i < calendarYearCells.length; i++) {
        _loop2(i);
      }

      callback ? callback() : null;
    },
    switchYearCalendar: function switchYearCalendar(date, type, callback) {
      if (this.calendarSwitching) return;
      var that = this;
      this.calendarSwitching = true;
      this.calendarDate = date;
      var calendarYearFirstDom = document.getElementsByClassName('calendar-year-render')[0];
      var calendarYearDom = calendarYearFirstDom.cloneNode(true);
      var calendarYearDomHeight = calendarYearFirstDom.getBoundingClientRect().height;
      var calendar_year_list = document.getElementById('calendar_year_list');
      var calendarYearCells = calendarYearDom.getElementsByClassName('calendar-cell');

      var _iterator2 = _createForOfIteratorHelper(calendarYearCells),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var d = _step2.value;
          d.classList.remove('now-day');
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      that.renderCalendarYearView(calendarYearDom, function () {
        if (type === 'next') {
          calendar_year_list.appendChild(calendarYearDom);
          $('#calendar_year_list').css({
            height: calendarYearDomHeight * 2,
            bottom: '-' + calendarYearDomHeight + 'px'
          });
          $('#calendar_year_list').animate({
            bottom: '0px'
          }, 300, function () {
            calendar_year_list.removeChild(calendarYearFirstDom);
            calendar_year_list.removeAttribute('style');
            that.calendarSwitching = false;
            callback ? callback(true) : null;
          });
        } else {
          $('#calendar_year_list').css({
            height: calendarYearDomHeight * 2,
            top: '-' + calendarYearDomHeight + 'px'
          });
          calendar_year_list.insertBefore(calendarYearDom, calendarYearFirstDom);
          $('#calendar_year_list').animate({
            top: '0px'
          }, 300, function () {
            calendar_year_list.removeChild(calendarYearFirstDom);
            calendar_year_list.removeAttribute('style');
            that.calendarSwitching = false;
            callback ? callback(true) : null;
          });
        }
      });
    }
  },
  mounted: function mounted() {
    if (!this.initCalendar) {
      return;
    }

    var that = this;
    this.renderCalendar(document.getElementsByClassName('calendar-date-render')[0]);
    this.renderCalendarYearView(document.getElementsByClassName('calendar-year-render')[0]);
    this.renderCalendarMonthView();
    var isStart = false;

    document.getElementById('switchPrevMonthBtn').onclick = function () {
      if (that.nowCalendarView === 1) {
        var date = that.calendarDate;
        date.setFullYear(date.getFullYear() - 1);
        document.getElementById('myCalendarYearSelect').innerText = date.getFullYear() + '年';
        return;
      }

      if (isStart) return;
      isStart = true;

      if (that.nowCalendarView === 2) {
        var _date = that.calendarDate;

        _date.setFullYear(_date.getFullYear() - 16);

        that.switchYearCalendar(_date, 'prev', function () {
          isStart = false;
        });
      } else {
        var _date2 = that.calendarDate;

        _date2.setMonth(_date2.getMonth() - 1);

        that.switchCalendar(_date2, 'prev', function () {
          isStart = false;
        });
      }
    };

    document.getElementById('switchNextMonthBtn').onclick = function () {
      if (that.nowCalendarView === 1) {
        var date = that.calendarDate;
        date.setFullYear(date.getFullYear() + 1);
        document.getElementById('myCalendarYearSelect').innerText = date.getFullYear() + '年';
        return;
      }

      if (isStart) return;
      isStart = true;

      if (that.nowCalendarView === 2) {
        var _date3 = that.calendarDate;

        _date3.setFullYear(_date3.getFullYear() + 16);

        that.switchYearCalendar(_date3, 'next', function () {
          isStart = false;
        });
      } else {
        var _date4 = that.calendarDate;

        _date4.setMonth(_date4.getMonth() + 1);

        that.switchCalendar(_date4, 'next', function () {
          isStart = false;
        });
      }
    };

    document.getElementById('myCalendarYearSelect').onclick = function () {
      if (that.nowCalendarView === 2) return;
      that.nowCalendarView = 2;
      $('#calendar_date_list').parent().hide();
      $('#calendar_year_list').parent().show();
      that.addClass(document.getElementsByClassName('calendar-year-render')[0], 'calendar-date-narrow-2');
      setTimeout(function () {
        $('.calendar-year-render').removeClass('calendar-date-narrow-2');
        $('.calendar-week').css('opacity', '0');
        $('#calendar_month_list').parent().hide();
      }, 290);
    };

    document.getElementById('myCalendarMonthSelect').onclick = function () {
      if (that.nowCalendarView === 1) return;
      that.nowCalendarView = 1;
      $('#calendar_date_list').parent().hide();
      $('#calendar_month_list').parent().show();
      that.addClass(document.getElementsByClassName('calendar-month-render')[0], 'calendar-date-narrow-2');
      setTimeout(function () {
        $('.calendar-month-render').removeClass('calendar-date-narrow-2');
        $('.calendar-week').css('opacity', '0');
        $('#calendar_year_list').parent().hide();
      }, 290);
    };
  }
};