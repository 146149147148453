import "core-js/modules/es.string.pad-start.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.concat.js";
import CryptoJS from "../tools/CryptoJS";
import DateTool from "../tools/DateTool";
import seasonPoenry from "@/static/json/season_poenry.json";
import Calendar from "./Calendar";
import UrlTool from "../tools/UrlTool";
export default {
  props: ['sideBarType', 'initCalendar', 'article'],
  components: {
    Calendar: Calendar
  },
  data: function data() {
    return {
      isInitClock: true,
      author: {
        nick: '作者',
        sign: '个新签名',
        avatarUrl: '/FileService/images/a1/avatar-01.jpg'
      },
      clock: {
        time: '00:00:00',
        date: '--年-月-日'
      },
      clockTimer: '',
      solarTermIcon: '',
      seasonPoenry: null,
      spIndex: 0,
      spText: '',
      spAuthor: '',
      upToDateArticle: [],
      articleLoaded: false,
      poems: [],
      poemsLoaded: false,
      ITNews: [],
      ITNewsLoaded: false,
      internetNews: [],
      internetNewsLoaded: false,
      shareIconsElem: ''
    };
  },
  methods: {
    openNewPage: function openNewPage(href) {
      window.open(href);
    },
    updateTime: function updateTime() {
      var cd = new Date();
      this.clock.time = cd.getHours().toString().padStart(2, '0') + ':' + cd.getMinutes().toString().padStart(2, '0') + ':' + cd.getSeconds().toString().padStart(2, '0');
    },
    getAuthorInfo: function getAuthorInfo() {
      console.log("getAuthorInfo");
    },
    switchSeasonPoenry: function switchSeasonPoenry() {
      if (!this.seasonPoenry) {
        var month = new Date().getMonth();

        switch (month) {
          case 0:
          case 1:
          case 2:
            this.solarTermIcon = require('../assets/solar-term/spring-01.png');
            this.seasonPoenry = seasonPoenry['spring'];
            break;

          case 3:
          case 4:
          case 5:
            this.solarTermIcon = require('../assets/solar-term/summer-01.png');
            this.seasonPoenry = seasonPoenry['summer'];
            break;

          case 6:
          case 7:
          case 8:
            this.solarTermIcon = require('../assets/solar-term/autumn-01.png');
            this.seasonPoenry = seasonPoenry['autumn'];
            break;

          case 9:
          case 10:
          case 11:
            this.solarTermIcon = require('../assets/solar-term/winter-01.png');
            this.seasonPoenry = seasonPoenry['winter'];
        }
      }

      var data = this.seasonPoenry[this.spIndex];
      this.spText = data['text'];
      this.spAuthor = data['author'];
      console.log(this.spText, this.spAuthor);
      this.spIndex = this.spIndex + 1;

      if (this.spIndex > this.seasonPoenry.length - 1) {
        this.spIndex = 0;
      }
    },
    toArticleDetails: function toArticleDetails(id) {
      this.$router.push({
        name: "Detail",
        query: {
          key: CryptoJS.encryptByDES(JSON.stringify({
            id: id,
            url: '/api/article/findById'
          })),
          _t: DateTool.getNowTime()
        }
      });
    },
    getUpToDateArticle: function getUpToDateArticle() {
      var that = this;
      that.upToDateArticle = [];
      that.articleLoaded = false;
      var params = {};
      this.post('/api/article/upToDateDiaryList', params).then(function (res) {
        that.upToDateArticle = res.data;
        that.articleLoaded = true;
      }).catch(function (error) {
        console.log(error);
      });
    },
    getITNews: function getITNews() {
      var that = this;
      that.ITNews = [];
      that.ITNewsLoaded = false;
      var params = {};
      this.post('/api/news/it', params).then(function (res) {
        that.ITNewsLoaded = true;

        if (res.code === 200) {
          that.ITNews = res.data;
        } else {
          console.log("IT news query failed");
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    getInternetNews: function getInternetNews() {
      var that = this;
      that.internetNews = [];
      that.internetNewsLoaded = false;
      var params = {};
      this.post('/api/news/internet', params).then(function (res) {
        that.internetNewsLoaded = true;

        if (res.code === 200) {
          that.internetNews = res.data;
        } else {
          console.log("internet news query failed");
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    getPoems: function getPoems() {
      var that = this;
      that.poems = [];
      that.poemsLoaded = false;
      var params = {
        count: 3
      };
      this.get('/api/poems/randomList', params).then(function (res) {
        that.poemsLoaded = true;

        if (res.code === 200) {
          that.poems = res.data;
        } else {
          console.log("poems query failed");
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    share: function share(type) {
      var article = this.article;
      var url = UrlTool.encode(window.location.href);
      var title = UrlTool.encode(article.title);

      if (type === 'weibo') {
        this.openNewPage("https://service.weibo.com/share/share.php?url=".concat(url, "&type=button&language=zh_cn\n                                &title=\u3010").concat(title, "\u3011%0A%0A%0A%0A%0A%0A%0A%0A%0A%0A%0A%0A%0A%0A%5B...%5D&searchPic=true#_loginLayer_").concat(new Date().getTime()));
      } else if (type === 'QQ') {
        this.openNewPage("https://connect.qq.com/widget/shareqq/index.html?url=".concat(url, "\n                &title=").concat(title, "&summary=%0A%0A%0A%0A%0A%0A%0A%0A%0A%0A%0A%0A%0A%0A%5B...%5D"));
      } else if (type === 'facebook') {
        this.openNewPage("https://www.facebook.com/sharer.php?u=".concat(url));
      } else if (type === 'twitter') {
        this.openNewPage("https://twitter.com/intent/tweet?url=".concat(url));
      } else if (type === 'weixin') {
        console.log('weixin');
      }
    }
  },
  mounted: function mounted() {
    var that = this;
    setTimeout(function () {//that.share('weibo')
    }, 5000);

    if (this.isInitClock) {
      console.log("init clock");
      this.updateTime();
      this.clockTimer = setInterval(function () {
        that.updateTime();
      }, 1000);
      var nowDate = new Date();
      that.clock.date = nowDate.getFullYear() + '年' + (nowDate.getMonth() + 1).toString().padStart(2, '0') + '月' + nowDate.getDate().toString().padStart(2, '0') + '日';
    }

    this.switchSeasonPoenry();
    this.getAuthorInfo();
    this.getUpToDateArticle();
    this.getPoems();
    this.getITNews();
    this.getInternetNews();
  },
  beforeUnmount: function beforeUnmount() {
    clearInterval(this.clockTimer);
  }
};